* {
  box-sizing: border-box;
  font-family: "Poppins";
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.school-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .image {
    width: 77px;
    height: 77px;
    border-radius: 10px;
    border: 1px solid #003595;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .name {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    font-weight: 500;
    color: #003595;
  }
}

.auth {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  .topbar {
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
    }
  }
  .container {
    .card {
      position: absolute;
      width: 538px;
      padding: 30px 56px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .title {
        font-size: 54px;
        line-height: 81px;
      }
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        .form-control {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 16px;
          line-height: 25px;
          .label {
            font-weight: 700;
            color: #913a7e;
          }
          input {
            height: 40px;
            border-radius: 20px;
            border: 1px solid #913a7e;
            padding: 9px 20px;
          }
        }
      }
      .btn {
        cursor: pointer;
        width: 215px;
        height: 54px;
        background-color: #913a7e;
        border: 1px solid #913a7e;
        border-radius: 34px;
        box-shadow: 0px 3px 6px #00000029;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 200px;
  min-width: 200px;
  z-index: 1000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  height: 100vh;

  .icon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .logo {
    margin-top: 37px;
    display: flex;
    justify-content: center;
  }

  .navmenu {
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
      padding-left: 36px;
      display: flex;
      flex-direction: row;
      gap: 10.5px;
      align-items: center;
      text-decoration: none;
      .text {
        color: #003595;
      }

      svg {
        width: 24px;
        fill: #003595;
      }
    }
    a.active {
      .text {
        font-weight: 700;
      }
    }
  }

  .logout {
    cursor: pointer;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    background-color: #913a7e;
    color: #ffffff;
    width: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: 500;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}
.top {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .messagebar {
    cursor: pointer;
    position: relative;
    min-width: 333px;
    width: 333px;
    background: #913a7e;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    .btn {
      position: absolute;
      right: 12px;
      bottom: 9px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background: #cccc00;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        stroke: #003595;
      }
    }
  }

  .topbar {
    width: 100%;
    height: 61px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 24px 8px 22px;
    box-shadow: 0px 3px 6px #00000029;
    background: #ffffff;

    .search > .icon {
      display: none;
      align-self: center;
      cursor: pointer;
      svg {
        height: 24px;
        fill: #913a7e;
      }
    }

    .search {
      width: 100%;
      max-width: 549px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      .select {
        padding: 10px 16px;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        top: 40px;
        background-color: #ffffff;
        border-radius: 8px;
        .user {
          cursor: pointer;
          font-size: 16px;
          line-height: 25px;
          color: #003595;
        }
      }
    }

    .circles {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .circle {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 700;
      }
      .first {
        background: #f4b200;
      }
      .second {
        background: #913a7e;
      }
      .third {
        background: #003595;
      }
    }
  }
}
.search {
  position: relative;
  input {
    height: 40px;

    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 21px;
  }

  .search-icon {
    position: absolute;
    min-width: 30px;
    width: 30px;
    height: 30px;
    background: #913a7e;
    top: 5px;
    right: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      content: "";
      display: block;
      background: #ffffff;
      width: 14px;
      height: 14px;
      mask: url("./assets/Icons/Search\ -\ New\ Gray.svg");
      -webkit-mask: url("./assets/Icons/Search\ -\ New\ Gray.svg");
    }
  }
}

input {
  border: none;
  outline: none;
  width: 100%;
}

.message-sidebar {
  width: 333px;
  min-width: 333px;
  height: 100vh;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 3px 8px #00000029;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    height: 40px;
    box-shadow: 0px 3px 6px #00000029;
    justify-content: center;
    background: #913a7e;
    cursor: pointer;

    padding-top: 11px;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
    font-weight: 700;
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: calc(100% - 55px);
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 12px;
    padding-bottom: 55px;

    .users {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dddddd;

      .user-group {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .select {
          position: relative;
          .options {
            display: none;
          }
        }
        .select.open {
          .options {
            display: flex;
            position: absolute;
            min-width: 100%;
            width: max-content;
            gap: 8px;
            padding: 4px 10px;
            border: 1px solid #9f9f9f;
            background-color: #ffffff;
            border-radius: 8px;
            .small-text {
              cursor: pointer;
            }
          }
        }
      }
      .user {
        cursor: pointer;
        height: 24px;
        border: 1px solid #9f9f9f;
        border-radius: 19px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 11px;
        padding-right: 8px;
        gap: 8px;
        .name {
          color: #3f3f3f;
          text-transform: capitalize;
        }
        .icon {
          cursor: pointer;
          width: 14px;
          height: 14px;
          background: #003595;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        background: #cccc00;
        border-radius: 9px;
        cursor: pointer;
      }
    }

    .messages {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      flex: 1 1 auto;
      padding: 10px;
      .message {
        display: flex;
        flex-direction: column;
        gap: 3px;
        .name {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: #003595;
          display: flex;
          flex-direction: row;
          gap: 4px;
          .text-muted {
            color: gray;
            text-transform: uppercase;
            font-weight: 400;
          }
        }
        .detail {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 4px;
          .avatar {
            width: 30px;
            height: 30px;
          }
        }

        .text {
          padding: 4px 10px;
          border: 1px solid #dddddd;
          border-radius: 10px;
          font-size: 12px;
          line-height: 18px;
          max-width: calc(100% - 120px);
          color: #3f3f3f;
        }
      }
      .mime {
        .name {
          padding-left: 38px;
        }
      }
      .other {
        align-items: flex-end;
        .name {
          padding-right: 38px;
        }
        .detail {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .chat {
    width: 100%;
    height: 40px;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding: 11px 8px 11px 18px;
    border-radius: 21px;

    .btn {
      cursor: pointer;
      min-width: 30px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #913a7e;
      border-radius: 21px;

      .icon::after {
        content: "";
        display: block;
        background: #ffffff;
        width: 16px;
        height: 14px;
        mask: url("./assets/Icons/Messages.svg");
      }
    }
  }
}

.container {
  padding: 30px 20px 20px 30px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  gap: 24px;
  overflow: auto;
  color: #003595;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .select {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon {
        position: absolute;
        right: 9px;
        display: inline-flex;
        align-items: center;
        height: 40px;
        .remove {
          background-color: #dddddd;
          display: inline-flex;
          cursor: pointer;
          z-index: 100;
          height: 16px;
          width: 16px;
          align-items: center;
          justify-content: center;
          margin-right: 2px;
          svg {
            width: 7px;
            height: 7px;
          }
        }
        svg {
          width: 12px;
          height: 7px;
          fill: #913a7e;
        }
      }
      input {
        width: 150px;
        cursor: pointer;
        background-color: #ffffff;
        color: #003595;
        font-weight: 500;
        display: flex;
        padding: 9px 15px;
        align-items: center;
        height: 40px;
        border: 1px solid #913a7e;
        border-radius: 20px;
      }
      .detail {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .image {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
      .btn {
        display: inherit;
        min-width: 240px;
        border: 1px solid #913a7e;
        padding: 4px 36px 4px 10px;
        min-height: 40px;
        border-radius: 4px;
        .item {
          margin-right: 4px;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          width: fit-content;
          padding: 4px;
          gap: 4px;
          background-color: #dddddd;
          .text {
            font-size: 10px;
            line-height: 16px;
          }
          svg {
            width: 6px;
          }
        }
      }
      .options {
        width: 100%;
        margin-top: 4px;
        background-color: #ffffff;
        padding: 8px 20px;
        border: 1px solid #913a7e;
        border-radius: 4px;
        display: none;
        position: absolute;
        align-items: flex-start;
        top: 40px;
        max-height: 180px;
        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow: auto;
        }

        .text {
          cursor: pointer;
        }
      }
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #003595;
        border-radius: 4px;
      }
    }
    .select.open {
      .options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 100;
      }
    }
    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      .back {
        width: 14px;
        height: 14px;
        transform: matrix(1, 0, 0, 1, 0, 0);
        background-color: #003595;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 7px;
      }
      .back-text {
        padding-left: 8px;
        padding-right: 24px;
        text-decoration: underline;
        font: normal normal normal 20px/30px Poppins;
        letter-spacing: 0px;
        color: #913a7e;
      }
    }
    .title {
      font-size: 20px;
      line-height: 30px;
      color: #003595;
      font-weight: 700;
    }
    .btn {
      cursor: pointer;
      width: 180px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #913a7e;
      background: #ffffff;
      box-shadow: 0px 3px 2px #00000029;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 11px;
      .text {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #003595;
      }
      .plus {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #913a7e;
      }
    }
  }

  .card-group {
    display: flex;
    flex-direction: row;
    gap: 24px;
    .card-tiny {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 24px;
      height: 47px;
      padding: 3px 16px;
      align-items: center;
      .text {
        font: normal normal bold 20px/30px Poppins;
      }
      .yellow {
        color: #f4b200;
      }
      .coin-card {
        margin-right: -12px;
        width: 80px;
        height: 41px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 24px;
        background: #f4b200 0% 0% no-repeat padding-box;
        display: flex;
        align-items: center;
        justify-content: center;
        .white {
          color: #fff;
        }
      }
    }
  }

  .Academic {
    color: #cccc00 !important;
    svg {
      fill: #cccc00 !important;
    }
  }
  .Behavioral {
    color: #003595 !important;
    svg {
      fill: #003595 !important;
    }
  }
  .Parent {
    color: #913a7e !important;
    svg {
      fill: #913a7e !important;
    }
  }
  .filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    .search {
      width: 296px;
    }
    .type {
      display: flex;
      flex-direction: row;
      gap: 24px;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0px;
      div {
        cursor: pointer;
      }
      .bold {
        font-weight: 700;
      }
    }
    .btn {
      cursor: pointer;
      width: 101px;
      height: 40px;
      border: 1px solid #913a7e;
      border-radius: 21px;
      align-items: center;
      justify-content: space-between;
      padding-left: 16px;
      padding-right: 10px;
      display: flex;
      .text {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #003595;
      }
      .icon {
        width: 30px;
        height: 30px;
        background: #913a7e;
        border-radius: 30px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .card.goals {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 18px 24px;
    gap: 24px;

    .teachers {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .divider {
        border-right: 1px solid #913a7e;
        height: 14px;
      }
      .label {
        color: #003595;
        font-weight: 700;
        padding-right: 8px;
      }
      .teacher {
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        color: #913a7e;
        border-bottom: 1px solid transparent;
      }
      .teacher.underline {
        border-bottom: 1px solid #cccc00;
      }
    }

    .search {
      max-width: 385px;
      height: 40px;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        padding: 10px 16px;
        border: 1px solid #dddddd;
        border-radius: 21px;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        color: #003595;
      }
      .btn {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: #913a7e;
        border-radius: 15px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 14px;
        }
      }
    }
    .goal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .detail {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        width: 100%;
        .step {
          font-size: 16px;
          line-height: 25px;
          font-weight: 700;
          letter-spacing: 0px;
          color: #913a7e;
        }
        .name {
          font-size: 16px;
          line-height: 25px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #003595;
          overflow: hidden;
          width: calc(100% - 80px);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          text-align: justify;
          overflow: hidden;
        }
      }
      .btn {
        cursor: pointer;
        width: 77px;
        height: 24px;
        border-radius: 24px;
        border: 1px solid #913a7e;
        box-shadow: 0px 3px 6px #00000029;
        color: #913a7e;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn:hover {
        color: #ffffff;
        background: #913a7e;
      }
    }
  }

  .card.coins.students {
    padding: 14px 24px;
    gap: 0;
    .row.header {
      padding-top: 40px;
      border-bottom: none;
    }
    .row {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #dddddd;
      justify-content: flex-start;
      .name {
        min-width: 150px;
      }
      .subject {
        min-width: 150px;
      }
      .date {
        min-width: 150px;
        display: flex;
        text-align: center;
        justify-content: center;
      }
      .amount {
        width: 100px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle {
          width: 26px;
          height: 26px;
          border-radius: 13px;
          font-size: 9px;
          line-height: 13px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #913a7e;
        }
      }
    }
  }
  .card.students {
    flex: 1 1 auto;
    padding: 40px 50px 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .flex-col {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .text {
          font: normal normal medium 16px/25px "Poppins";
          font-weight: 500;
          letter-spacing: 0px;
          color: #003595;
        }
        .btn.yellow {
          display: flex;
          padding: 8px 9px 8px 16px;
          gap: 8px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid #913a7e;
          align-items: center;
          justify-content: space-between;
          .icon {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background: #f4b200;
            color: #913a7e;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              stroke: #913a7e;
            }
          }
        }
      }
    }
    .label {
      font-size: 16px;
      line-height: 25px;
      font-weight: 700;
      color: #003595;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 50px;
      align-items: center;
    }
    .count {
      text-align: center;
      align-items: center;
    }

    .action {
      display: flex;
      align-items: flex-end;
    }

    .col {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .item {
        height: 60px;
        display: flex;
        align-items: center;
      }
    }

    .row.small {
      .item {
        height: 40px !important;
      }
      .names {
        .image {
          width: 40px !important;
          height: 40px !important;
          border-radius: 20px !important;
          min-width: 40px !important;
          overflow: hidden;
          border: 1px solid #913a7e;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }

      .names,
      .school {
        gap: 10px;
      }
    }

    .names {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      .image {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;
        border: 1px solid #913a7e;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .image.reward {
        border-color: transparent;
      }
      .name {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #003595;
        cursor: pointer;
      }
    }

    .school {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .image {
        width: 24px;
        height: 24px;
        border: 1px solid #003595;
        border-radius: 1px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #003595;
      }
    }

    .progress {
      height: 60px;
      align-self: flex-start;
      .type {
        display: flex;
        flex-direction: row;
        gap: 16px;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;
        div {
          cursor: pointer;
        }
        .bold {
          text-decoration: underline;
          font-weight: 700;
        }
      }
      .progress-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .line {
          height: 0px;
          width: 180px;
          border-radius: 3px;
          border-bottom: 3px solid #dddddd;
          .color-line {
            height: 0px;
            border-radius: 3px;
            border-bottom: 3px solid currentColor;
          }
        }
        .text {
          font-size: 8px;
          line-height: 12px;
          font-weight: 700;
          line-height: 0;
          min-width: 24px;
        }
      }
    }
    .action {
      .btn {
        cursor: pointer;
        width: 77px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #913a7e;
        border-radius: 24px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 700;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
      }
    }
  }

  .card.messages {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 24px 24px 20px 30px;
    gap: 24px;
    .title {
      font-size: 16px;
      line-height: 25px;
      font-weight: 700;
      letter-spacing: 0px;
    }
    .messages {
      width: calc(100% - 240px);
      padding-right: 16px;
      border-right: 1px solid #dddddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      .row {
        cursor: pointer;
        .icon {
          display: none;
          svg {
            width: 24px;
            height: 24px;
            fill: #003595;
          }
        }
      }
      .chat {
        position: relative;
        input {
          width: 100%;
          height: 40px;
          border-radius: 21px;
          border: 1px solid #dddddd;
          padding: 10px 16px;
        }
        .icon {
          position: absolute;
          cursor: pointer;
          top: 5px;
          right: 10px;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background: #913a7e;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 16px;
            fill: currentColor;
            stroke: currentColor;
          }
        }
        button {
          outline: none;
          border: none;
        }
      }
      .chat-messages::-webkit-scrollbar {
        width: 0px;
      }
      .chat-messages {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;
        gap: 12px;

        .message {
          display: flex;
          flex-direction: column;
          gap: 3px;
          .name {
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #003595;
            display: flex;
            flex-direction: row;
            gap: 4px;
            .text-muted {
              color: gray;
              text-transform: uppercase;
              font-weight: 400;
            }
          }
          .detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 8px;
          }

          .text {
            padding: 10px 16px;
            border: 1px solid #dddddd;
            border-radius: 10px;
            font-size: 12px;
            line-height: 18px;
            max-width: calc(100% - 120px);
            color: #3f3f3f;
          }
        }
        .mime {
          .name {
            padding-left: 38px;
          }
        }
        .other {
          align-items: flex-end;
          .name {
            padding-right: 38px;
          }
          .detail {
            flex-direction: row-reverse;
          }
        }
      }
    }
    .users {
      width: 240px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      height: 100%;
      .close {
        .icon {
          display: none;
        }
      }
      .btn {
        cursor: pointer;
        height: 40px;
        border-radius: 20px;
        color: #913a7e;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border: 1px solid #913a7e;
      }
      .part {
        display: flex;
        flex-direction: column;
        height: 50%;
        gap: 24px;
      }
      .title {
        color: #913a7e;
      }
      .col {
        display: flex;
        flex-direction: column;
        height: calc(100% - 50px);
        overflow: auto;
        gap: 8px;

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          .detail {
            display: flex;
            flex-direction: column;
            .name {
              font-weight: 500;
              color: #003595;
            }
            .action {
              display: flex;
              flex-direction: row;
              gap: 8px;
              .status {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
                .circle {
                  width: 8px;
                  height: 8px;
                  border-radius: 4px;
                }
                .green {
                  background-color: #34ce57;
                }
                .red {
                  background-color: #e4606d;
                }
                .text {
                  font-size: 9px;
                  line-height: 13px;
                  letter-spacing: 0px;
                  color: #003595;
                  text-transform: capitalize;
                }
              }
              .action {
                font-size: 9px;
                line-height: 13px;
                text-decoration: underline;
                text-transform: capitalize;
              }
              .action.message.plus {
                color: #003595;
                font-weight: 700;
              }
              .action.message {
                font-weight: 500;
                color: #913a7e;
              }
              .action.remove {
                font-weight: 500;
                color: #003595;
              }
            }
          }
        }
      }
    }
  }

  .blue {
    border-radius: 10px;
    background: #3c89e6;
    height: 180px;
    min-height: 180px;
    padding-top: 40px;
    padding-left: 38px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      font-weight: 700;
      span {
        color: #cccc00;
      }
    }
    .text {
      font-weight: 500;
      width: 50%;
      span {
        font-weight: 700;
        color: #913a7e;
      }
    }
  }

  .tracking {
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      width: 40%;
      font-size: 24px;
      line-height: 35px;
    }
  }

  .category {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      height: 48px;
      border-radius: 24px;
      box-shadow: 0px 3px 6px #00000029;
      align-items: center;
      background: #ffffff;

      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      width: 250px;
    }

    .students {
      color: #003595;
    }
    .goals {
      color: #f4b200;
    }
    .coins {
      color: #003595 !important;
      width: 100%;
    }
  }

  .details {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }

  .grow {
    flex-grow: 1;
  }

  .card {
    width: 100%;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 24px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .tabs {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .tab {
        cursor: pointer;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;
        color: #003595;
        font-weight: 500;
      }
      .active {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .title {
      color: #003595;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      letter-spacing: 0;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0;

      .detail {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }

      .image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }

      .name {
        color: #003595;
      }

      .btn {
        cursor: pointer;
        width: 77px;
        height: 24px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #913a7e;
        box-shadow: 0px 3px 6px #00000029;
        font-size: 13px;
        line-height: 18px;
        font-weight: 700;
        background-color: #ffffff;
        color: #913a7e;
      }

      .btn:hover {
        background-color: #913a7e;
        color: #ffffff;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .divider {
        height: 0;
        width: 100%;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 8px;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;

        .result {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          .line {
            height: 0px;
            width: 100px;
            border-bottom: 3px solid #dddddd;
            border-radius: 2px;

            .progress {
              height: 0px;
              border-bottom: 3px solid currentColor;
              border-radius: 2px;
            }
          }
          .text {
            font-size: 8px;
            line-height: 12px;
            letter-spacing: 0px;
            font-weight: 700;
            text-align: right;
          }
        }

        .first {
          color: #913a7e;

          .progress {
            width: 20%;
          }
        }

        .third {
          color: #f4b200;
          .progress {
            width: 60%;
          }
        }

        .forth {
          color: #cccc00;
          .progress {
            width: 80%;
          }
        }

        .label {
          font-weight: 500;
          color: #913a7e;
        }
      }
    }
  }

  .card.more {
    flex: 1 1 auto;
    gap: 0;
    padding-bottom: 0;
  }

  .card.rewards {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 48px;
    .rewards {
      display: flex;
      flex-direction: column;
      .header {
        color: #003595;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        justify-content: flex-start;
      }
      .image {
        width: 70px;
      }
      .coin {
        display: flex;
        justify-content: center;
        .circle {
          width: 26px;
          height: 26px;
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          background-color: #003595;
          font-size: 9px;
          line-height: 13px;
        }
      }
      .reward {
        padding: 12px 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        justify-content: space-between;
        .detail {
          display: flex;
          flex-direction: row;
          gap: 28px;
          align-items: center;
          .name {
            font-size: 16px;
            line-height: 25px;
            color: #003595;
            letter-spacing: 0;
          }
        }
        .image {
          width: 40px;
          height: 40px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .btn {
          cursor: pointer;
          width: 77px;
          height: 24px;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #913a7e;
          border-radius: 24px;
          color: #913a7e;
          font-size: 13px;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }
        .btn:hover {
          background-color: #913a7e;
          color: #ffffff;
        }
      }
    }
  }
}

.container.tracking {
  .blue {
    display: flex;
    padding: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .graphs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 80px;
    align-items: center;
    .lines {
      display: flex;
      flex-direction: row;
      gap: 24px;
      .line {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 0px;
        width: 0px;
        height: 112px;
        border-radius: 8px;
        border-right: 8px solid #dddddd;
      }
      .progress {
        position: absolute;
        bottom: 0;
        width: 0px;
        border-radius: 8px;
        border: 4px solid currentColor;
      }
    }
    .circles {
      display: flex;
      flex-direction: row;
      .circle {
        width: 200px;
        height: 200px;
      }
    }
  }
}
.container.messages {
  padding: 40px;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #dddddd;
}

::-webkit-scrollbar-track {
  width: 8px;
  background-color: #f3efef;
}

a {
  text-decoration: none;
}

.container.profile {
  display: flex;
  flex-direction: row;
  gap: 16px;
  .card {
    padding: 24px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: fit-content;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .score {
          font-size: 16px;
          line-height: 25px;
          font-weight: 500;
          color: #003595;
          display: flex;
          flex-direction: row;
          gap: 4px;
          .value {
            font-weight: 700;
            color: #f4b200;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .btn {
          cursor: pointer;
          width: 96px;
          height: 40px;
          border-radius: 20px;
          background-color: #f2eee9;
          border: none;
          box-shadow: none;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 5px 8px;
          .text {
            font-size: 13px;
            line-height: 18px;
            font-weight: 500;
          }
          .icon {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background-color: #f4b200;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 16px;
              height: 16px;
              fill: #913a7e;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .gray-container {
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        border-radius: 4px;
        background: #f5f5f5;
        .graph {
          margin-left: -25px;
        }
        .description {
          color: #003595;
          background: #ffffff;
          width: 100%;
          min-width: 150px;
          border-radius: 4px;
          padding: 16px 12px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .date {
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
          }
          .text {
            font-size: 13px;
            line-height: 18px;
            word-break: break-all;
          }
          .bold {
            font-weight: 700;
          }
          .medium {
            font-weight: 500;
          }
          .details {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .detail {
              display: flex;
              flex-direction: row;
              gap: 4px;
            }
          }
        }
      }
    }
    .details {
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      color: #003595;
      display: flex;
      flex-direction: row;
      gap: 16px;
      .detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .btn {
          width: 172px;
          height: 40px;
          padding: 8px 16px;
          display: flex;
          flex-direction: row;
          background-color: #f2eee9;
          border-radius: 20px;
          align-items: center;
          justify-content: space-between;
          .icon {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: #f4b200;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .records {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .record-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 8px 12px 8px 20px;
          width: 180px;
          border-radius: 20px;
          height: 40px;
          border: 1px solid #913a7e;
          color: #913a7e;
          font-size: 16px;
          line-height: 25px;
          font-weight: 500;
          .text {
            color: #003595;
          }
        }
        .btn-group {
          display: flex;
          flex-direction: row;
          gap: 8px;
          .btn {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            line-height: 18px;
          }
          .btn.fill {
            background-color: #003595;
            border-color: #003595;
            color: #ffffff;
          }
        }
      }
      .table > .row {
        justify-content: flex-start;
      }
      .table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;

        .col {
          display: flex;
          flex-direction: column;
          .tbody {
            border-left: none;
          }
          .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #003595;
            font-size: 13px;
            line-height: 18px;
          }
        }
        .col:first-of-type {
          .thead {
            border-radius: 4px 0px 0px 0px;
          }

          .tbody {
            border-left: 1px solid #003595;
          }

          .tbody:last-of-type {
            border-radius: 0px 0px 0px 4px;
          }
        }
        .col:last-of-type {
          .thead {
            border-radius: 0px 4px 0px 0px;
          }
          .tbody:last-of-type {
            border-radius: 0px 0px 4px 0px;
          }
        }
        .thead {
          background-color: #913a7e;
          width: 100%;
          color: #ffffff;
          svg {
            stroke: currentColor;
          }
        }
        .tbody {
          border-left: 1px solid #003595;
          border-right: 1px solid #003595;
          .row {
            border-bottom: 1px solid #003595;
            color: #003595;
            .select,
            .line {
              border-right: 1px solid #003595;
            }
            .score,
            .date {
              border-right: 1px solid #003595;
            }
          }
        }
        .row {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          min-height: 40px;
          .select,
          .line {
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .date,
          .score {
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .notes {
            width: 100%;
            .row {
              padding: 2px 8px;
              justify-content: flex-start;
            }
            .tbody {
              height: 36px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
              text-align: justify;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .card-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    .card {
      padding: 24px 20px 30px 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .select {
          display: flex;
          flex-direction: column;
          position: relative;

          .icon {
            position: absolute;
            right: 9px;
            display: inline-flex;
            align-items: center;
            height: 40px;
            .remove {
              background-color: #dddddd;
              display: inline-flex;
              cursor: pointer;
              z-index: 100;
              height: 16px;
              width: 16px;
              align-items: center;
              justify-content: center;
              margin-right: 2px;
              svg {
                width: 7px;
                height: 7px;
              }
            }
            svg {
              width: 12px;
              height: 7px;
              fill: #913a7e;
            }
          }
          input {
            width: 150px;
            cursor: pointer;
            background-color: #ffffff;
            color: #003595;
            font-weight: 500;
            display: flex;
            padding: 9px 15px;
            align-items: center;
            height: 40px;
            border: 1px solid #913a7e;
            border-radius: 20px;
          }
          .detail {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .image {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              img {
                width: 100%;
              }
            }
          }
          .btn {
            display: inherit;
            min-width: 240px;
            border: 1px solid #913a7e;
            padding: 4px 36px 4px 10px;
            min-height: 40px;
            border-radius: 4px;
            .item {
              margin-right: 4px;
              cursor: pointer;
              display: inline-flex;
              align-items: center;
              width: fit-content;
              padding: 4px;
              gap: 4px;
              background-color: #dddddd;
              .text {
                font-size: 10px;
                line-height: 16px;
              }
              svg {
                width: 6px;
              }
            }
          }
          .options {
            width: 100%;
            margin-top: 4px;
            background-color: #ffffff;
            padding: 8px 20px;
            border: 1px solid #913a7e;
            border-radius: 4px;
            display: none;
            position: absolute;
            align-items: flex-start;
            top: 40px;
            max-height: 180px;
            .details {
              display: flex;
              flex-direction: column;
              gap: 10px;
              overflow: auto;
            }

            .text {
              cursor: pointer;
            }
          }
          ::-webkit-scrollbar {
            width: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #003595;
            border-radius: 4px;
          }
        }
        .select.open {
          .options {
            display: flex;
            flex-direction: column;
            gap: 10px;
            z-index: 100;
          }
        }
        .title {
          font-size: 16px;
          line-height: 25px;
          color: #003595;
          font-weight: 700;
        }

        .title {
          font-size: 16px;
          line-height: 25px;
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          .icon {
            svg {
              width: 12px;
              height: 7px;
              fill: #003595;
            }
          }
        }
        .select {
          .btn {
            width: 180px;
            height: 40px;
            border-radius: 20px;
            padding: 10px 20px;
            box-shadow: 0px 3px 2px #00000029;
            border: 1px solid #913a7e;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .text {
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0px;
              color: #003595;
            }
            .icon {
              svg {
                fill: #913a7e;
                width: 12px;
                height: 7px;
              }
            }
          }
        }
      }
      .progress {
        display: flex;
        flex-direction: column;
        gap: 28px;

        .progress-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;
          color: #003595;
          .text {
            width: fit-content;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0px;
            font-weight: 700;
          }
          .line {
            width: calc(100% - 68px);
            height: 0px;
            border-radius: 4px;
            border-bottom: 8px solid #dddddd;
            .progress {
              border-radius: 4px;
              border: 4px solid currentColor;
            }
          }
        }
      }
      .divider {
        height: 0;
        border-bottom: 1px solid #dddddd;
      }
      .rewards {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 86px;
          .reward {
            width: 86px;
            height: 86px;
            position: relative;
            .image {
              width: 86px;
              height: 86px;
              border-radius: 43px;
              overflow: hidden;
              img {
                width: 86px;
                height: 86px;
              }
            }
            .mark {
              position: absolute;
              top: 0;
              right: -12px;
              width: 32px;
              height: 32px;
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #003595;
              color: #ffffff;
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .card.progress {
      display: flex;
      flex-direction: column;
      gap: 26px;
      padding-bottom: 66px;
      min-height: 300px;

      .goals {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .goal-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .status {
          font-size: 9px;
          line-height: 13px;
          letter-spacing: 0px;
          text-transform: capitalize;
        }
        .incomplete {
          color: #003595;
        }
        .completed {
          color: #f4b200;
        }
        .completion {
        }
      }

      .goal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .detail {
          display: flex;
          flex-direction: row;
          gap: 24px;
          align-items: center;
          width: 100%;
          .step {
            font-size: 16px;
            line-height: 25px;
            font-weight: 700;
            letter-spacing: 0px;
            color: #913a7e;
          }
          .name {
            font-size: 16px;
            line-height: 25px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #003595;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            text-align: justify;
            overflow: hidden;
            width: calc(100% - 80px);
          }
        }
        .btn {
          cursor: pointer;
          width: 77px;
          height: 24px;
          border-radius: 24px;
          border: 1px solid #913a7e;
          box-shadow: 0px 3px 6px #00000029;
          color: #913a7e;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn:hover {
          color: #ffffff;
          background: #913a7e;
        }
      }
    }
  }
  .card.profile {
    min-width: 270px;
    width: 270px;
    padding: 40px 14px 40px 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 24px;
    .header {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }

    .info {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      .image {
        min-width: 60px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #913a7e;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .status {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .status-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 500;
          gap: 8px;
          .label {
            font-size: 16px;
            line-height: 25px;
            color: #003595;
          }
          .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            .circle {
              width: 8px;
              height: 8px;
              border-radius: 4px;
            }
            .green {
              background: #2bb61b;
            }
            .text {
              font-size: 13px;
              line-height: 18px;
              color: #003595;
            }
          }
        }
        .login-info {
          font-size: 9px;
          line-height: 13px;
          letter-spacing: 0px;
          color: #707070;
        }
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #003595;
      font-weight: 500;
      width: 100%;
      .name {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
      }
      .email {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0px;
        width: 100%;
      }
    }
    .coins {
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;

      .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .text {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0px;
        font-weight: 700;
        color: #003595;
      }
      .circle {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        border: 3px solid #003595;
        color: #003595;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
      }
      .circle.active {
        border-color: #f4b200;
        background-color: #f4b200;
        color: #ffffff;
      }
      .redeem {
        cursor: pointer;
        color: #913a7e;
        text-decoration: underline;
        font-style: italic;
        font-size: 13px;
        line-height: 18px;
        font-weight: 700;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .change {
        display: flex;
        align-self: flex-end;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #003595;
        text-decoration: underline;
        font-style: italic;
      }
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text {
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0px;
          color: #003595;
          font-weight: 700;
        }
        .value {
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0px;
          color: #913a7e;
          text-transform: capitalize;
        }
      }
      .row.interests {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        .interests {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          .interest {
            height: 23px;
            padding: 5px 10px;
            border-radius: 12px;
            display: flex;
            align-self: center;
            font-size: 9px;
            line-height: 13px;
            color: #913a7e;
            border: 1px solid #913a7e;
          }
          .plus {
            cursor: pointer;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: #cccc00;
            font-size: 10px;
            line-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #913a7e;
          }
        }
      }
    }
    .goal-details {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .select {
        display: flex;
        flex-direction: column;
        position: relative;

        .icon {
          position: absolute;
          right: 9px;
          display: inline-flex;
          align-items: center;
          height: 25px;
          .remove {
            background-color: #dddddd;
            display: inline-flex;
            cursor: pointer;
            z-index: 100;
            height: 16px;
            width: 16px;
            align-items: center;
            justify-content: center;
            margin-right: 2px;
            svg {
              width: 7px;
              height: 7px;
            }
          }
          svg {
            width: 12px;
            height: 7px;
            fill: #913a7e;
          }
        }
        input {
          width: 100px;
          cursor: pointer;
          padding: 4px 10px;
          background-color: #ffffff;
          font-weight: 500;
          color: #913a7e !important;
          display: flex;
          align-items: center;
          height: 25px;
          border-radius: 20px;
        }
        .detail {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          .image {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
        }
        .btn {
          display: inherit;
          min-width: 240px;
          border: 1px solid #913a7e;
          padding: 4px 36px 4px 10px;
          min-height: 40px;
          border-radius: 4px;
          .item {
            margin-right: 4px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            width: fit-content;
            padding: 4px;
            gap: 4px;
            background-color: #dddddd;
            .text {
              font-size: 10px;
              line-height: 16px;
            }
            svg {
              width: 6px;
            }
          }
        }
        .options {
          width: 100%;
          margin-top: 4px;
          background-color: #ffffff;
          padding: 4px 10px;
          border: 1px solid #913a7e;
          border-radius: 4px;
          display: none;
          position: absolute;
          align-items: flex-start;
          top: 20px;
          max-height: 180px;
          .details {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: auto;
          }

          .text {
            cursor: pointer;
          }
        }
        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #003595;
          border-radius: 4px;
        }
      }
      .select.open {
        .options {
          display: flex;
          flex-direction: column;
          gap: 10px;
          z-index: 100;
        }
      }
      .detail {
        display: flex;
        flex-direction: row;
        gap: 8px;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0px;
        .text {
          color: #003595;
        }
        .value {
          color: #913a7e;
        }
        .icon {
          display: flex;
          align-items: center;
        }
        svg {
          width: 12px;
          height: 7px;
          fill: #913a7e;
        }
      }
      .detail.col {
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
    }

    .description {
      color: #003595;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .date {
        font-weight: 700;
      }
    }
    .score {
      color: #003595;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      b {
        font-weight: 700;
      }
    }
    .progress-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #003595;
      .line {
        height: 0px;
        width: calc(100% - 24px);
        border-bottom: 3px solid #dddddd;
        border-radius: 2px;
        .progress {
          border-bottom: 3px solid currentColor;
          border-radius: 2px;
        }
      }
      .text {
        font-size: 8px;
        line-height: 12px;
        font-weight: 700;
      }
    }
    .btn.complete {
      cursor: pointer;
      align-self: center;
      box-shadow: 0px 3px 2px #00000029;
      background: #003595;
      width: 180px;
      height: 40px;
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border-radius: 20px;
      text-transform: capitalize;
    }
  }
  .card.profile.progress {
    padding: 24px 20px 30px 20px;
  }
  .yAxis > line {
    display: none;
  }
  .yAxis > .recharts-cartesian-axis-ticks > .recharts-layer > line {
    stroke: #707070;
  }
  .straight {
    circle {
      display: none;
    }
  }
}

.modal {
  z-index: 10000;
  .card {
    padding: 20px 30px;
    z-index: 10000;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        padding-top: 12px;
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
        letter-spacing: 0px;
        color: #003595;
      }

      .btn {
        cursor: pointer;
        display: flex;
        align-self: flex-start;
        width: 71px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #913a7e;
        color: #913a7e;
        align-items: center;
        justify-content: center;
      }
    }
    .coins {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 9px;
    }
    .text {
      font-size: 16px;
      line-height: 25px;
      color: #003595;
      font-weight: 700;
    }
    .circle {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      font-size: 24px;
      line-height: 35px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #f4b200;
      color: #f4b200;
    }
    .rewards {
      padding-top: 11px;
      padding-bottom: 53px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;
        .reward {
          cursor: pointer;
          width: 86px;
          height: 86px;
          position: relative;

          .image {
            width: 86px;
            height: 86px;
            border-radius: 43px;
            overflow: hidden;
            img {
              width: 86px;
              height: 86px;
            }
          }
          .mark {
            position: absolute;
            top: 0;
            right: -12px;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #003595;
            color: #ffffff;
            font-size: 13px;
            line-height: 18px;
          }
          .selected {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 86px;
            height: 86px;
            background: #913a7e;
            border-radius: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 25px;
            font-weight: 700;
            color: #ffffff;
            opacity: 0.66;
          }
        }
      }
    }
    .btn-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 32px;
      .btn {
        cursor: pointer;
        width: 150px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 24px;
        border: 1px solid #913a7e;
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
      }
      .btn.deny {
        background-color: #ffffff;
        color: #913a7e;
      }
      .btn.confirm {
        background-color: #913a7e;
        color: #ffffff;
      }
    }
    .rewards.coins {
      gap: 16px;
      align-items: flex-start;
      .coins {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .coin {
          width: 100px;
          height: 46px;
          border: 1px solid #913a7e;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #913a7e;
        }
        .coin.active {
          color: #ffffff;
          background-color: #913a7e;
        }
      }
    }
    .explain {
      .text {
        color: #913a7e;
      }
      textarea {
        border: 1px solid #913a7e;
        width: 462px;
        padding: 9px 15px;
        border-radius: 4px;
        outline: none;
      }
    }
    .record {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .score {
      display: flex;
      flex-direction: row;
      gap: 20px;
      color: #913a7e;
      align-items: center;
      input {
        width: 100px;
        padding: 4px 10px;
        border-radius: 4px;
        border: 1px solid #913a7e;
        font-weight: 500;
      }
    }
  }
}

.panel {
  position: absolute;
  z-index: 5000;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.2;
}

.text {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
}

.small-text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0px;
}

.large-text {
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0px;
}

.bold {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500;
}

.details.parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  .card {
    width: 360px;
    height: 150px;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 215px;
      height: 68px;
      border-radius: 34px;
      border: 1px solid #913a7e;
      box-shadow: 0px 3px 6px #00000029;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      color: #003595;
    }
    .btn:hover {
      background-color: #913a7e;
      color: #ffffff;
    }
  }
}

.card.new {
  padding: 40px 40px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  .or {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    font-style: italic;
    color: #cccc00;
  }
  .btn.response {
    cursor: pointer;
    margin-top: -12px;
    box-shadow: 0px 3px 2px #00000029;
    border: 1px solid #913a7e;
    border-radius: 20px;
    width: 180px;
    height: 40px;
    display: flex;
    padding: 9px 15px;
    padding-right: 9px;
    justify-content: space-between;
    align-self: flex-end;
    align-items: center;
    .text {
      color: #003595;
      font-weight: 500;
    }
    .plus {
      color: #913a7e;
      font-weight: 500;
    }
  }
  .form-control {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .password {
      width: 100%;
      position: relative;
      .icon {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 9px;
        svg {
          width: 24px;
          color: #003595;
        }
      }
    }
    .text {
      color: #003595;
    }
    .header {
      align-items: center;
      .label {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .underline {
          color: #003595;
          text-decoration: underline;
        }
      }
      .search {
        input {
          width: 300px;
          border-radius: 20px;
        }
      }
    }
    .label {
      color: #913a7e;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0px;
      font-weight: 700;
    }
    input.text,
    input.grade,
    input.file {
      border: 1px solid #913a7e;
      border-radius: 4px;
      height: 40px;
      padding: 9px 15px;
      color: #003595;
    }
    textarea {
      border: 1px solid #913a7e;
      border-radius: 4px;
      padding: 9px 15px;
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
      outline: none;
      color: #003595;
      resize: none;
    }
    select {
      border: 1px solid #913a7e;
      border-radius: 4px;
      height: 40px;
      padding: 9px 15px;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      background: url("./assets/Images/downarrow.png") no-repeat right 22px
        center;
    }

    .group {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .select {
        width: calc(100% + 8px);
      }
      input {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
      }
    }

    .grade {
      width: 180px;
    }
    .file {
      width: fit-content;
      position: relative;
      .icon {
        position: absolute;
        cursor: pointer;
        top: 8px;
        right: -30px;
        svg {
          width: 24px;
          height: 24px;
          fill: #003595;
        }
      }
    }

    .table {
      border: 1px solid #913a7e;
      border-radius: 4px;
      color: #003595;
      overflow: hidden;
      .row.header {
        font-size: 13px;
        line-height: 18px;
        font-weight: 700;
        background: #f3f3f3;
      }
      .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #dddddd;
        .names {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;

          .image {
            display: flex;
            width: 60px;
            height: 60px;
            border: 1px solid #003595;
          }
        }
        .select {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          input[type="radio"] {
            cursor: pointer;
            width: 16px;
            height: 16px;
            accent-color: #913a7e;
            border: 1px solid #913a7e;
          }
        }
      }
    }

    .alert-message {
      font-size: 9px;
      line-height: 13px;
      color: red;
    }

    .select {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon {
        position: absolute;
        right: 9px;
        display: inline-flex;
        align-items: center;
        height: calc(100% - 10px);
        .remove {
          background-color: #dddddd;
          display: inline-flex;
          cursor: pointer;
          z-index: 100;
          height: 16px;
          width: 16px;
          align-items: center;
          justify-content: center;
          margin-right: 2px;
          svg {
            width: 7px;
            height: 7px;
          }
        }
        svg {
          width: 12px;
          height: 7px;
          fill: #913a7e;
        }
      }
      input {
        cursor: pointer;
      }
      .detail {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .image {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
      ::-webkit-scrollbar {
        height: 0px;
      }

      .btn {
        flex-wrap: wrap;
        display: inherit;
        min-width: 240px;
        border: 1px solid #913a7e;
        padding: 4px 36px 4px 10px;
        min-height: 40px;
        border-radius: 4px;
        gap: 4px;
        .item {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          width: fit-content;
          padding: 4px;
          gap: 4px;
          background-color: #dddddd;
          .text {
            font-size: 10px;
            line-height: 16px;
          }
          svg {
            width: 6px;
          }
        }
      }
      .options {
        width: 100%;
        margin-top: 4px;
        background-color: #ffffff;
        padding: 8px 20px;
        border: 1px solid #913a7e;
        border-radius: 4px;
        display: none;
        position: relative;
        align-items: flex-start;
        max-height: 180px;
        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow: auto;
        }

        .text {
          cursor: pointer;
        }
      }
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #003595;
        border-radius: 4px;
      }
    }
    .select.open {
      .options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 100;
      }
    }

    .options {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      .option {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #913a7e;
        color: #913a7e;
        min-width: 100px;
        padding: 5px 10px;
        height: 40px;
        border-radius: 20px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        input {
          border: none;
          background-color: transparent;
        }
      }
      .option.selected {
        background-color: #913a7e;
        color: #ffffff;
      }
      .plus {
        cursor: pointer;
        background: #cccc00;
        width: 21px;
        height: 21px;
        border-radius: 11px;
        color: #913a7e;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .date {
      position: relative;
      cursor: pointer;
      input {
        cursor: pointer;
        width: 172px;
        height: 40px;
        background: #f2eee9;
        padding: 8px 16px;
        border-radius: 20px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #003595;
      }
      .icon {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-color: #f4b200;
        display: flex;
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  img {
    width: 200px;
  }
  .form-group {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .submit {
    cursor: pointer;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 3px 2px #00000029;
    background: #003595;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
}

.details.progress {
  .card {
    gap: 24px;
  }
  .small-text {
    color: #003595;
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  .progress {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      font-size: 13px;
      line-height: 18px;
    }
    .progress-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      color: #003595;
      .text {
        width: fit-content;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0px;
        font-weight: 700;
      }
      .line {
        width: calc(100% - 68px);
        height: 0px;
        border-radius: 4px;
        border-bottom: 8px solid #dddddd;
        .progress {
          width: 60%;
          border-radius: 4px;
          border: 4px solid currentColor;
        }
      }
    }
  }
  .divider {
    height: 0;
    border-bottom: 1px solid #dddddd;
  }
  .rewards {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      font-size: 13px;
      line-height: 18px;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .reward {
        width: 70px;
        height: 70px;
        position: relative;
        .image {
          width: 70px;
          height: 70px;
          border-radius: 35px;
          overflow: hidden;
          img {
            width: 70px;
            height: 70px;
          }
        }
        .mark {
          position: absolute;
          top: 0;
          right: -12px;
          width: 26px;
          height: 26px;
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #003595;
          color: #ffffff;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.user-list-container {
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
}
.chat-online {
  color: #34ce57;
}
.chat-offline {
  color: #e4606d;
}
.list-group-item {
  padding: 0.4rem 1.25rem;
  font-size: 10pt;
}
.list-group-item-action:focus,
.list-group-item-action:hover,
.active-chat {
  background-color: #fff3cd;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position: fixed;
  background: white;
  min-width: 500px;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px;
  border-radius: 4px;
}
.modal-close {
  font-size: 16pt;
  margin-right: 5px;
  cursor: pointer;
  color: #913a7e;
}
.text-right {
  text-align: right;
}
.modal-body {
  height: 90%;
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  .users {
    height: 300px;
    overflow-y: auto;
    padding: 16px;
  }

  .users::-webkit-scrollbar {
    width: 3px;
  }
  .users::-webkit-scrollbar-thumb {
    background-color: #003595;
    border-radius: 2px;
  }

  .users::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    height: 48px;
    border-radius: 24px;
    align-items: center;
    .tab {
      color: #913a7e;
      font-size: 20px;
      line-height: 30px;
      padding: 9px 15px;
      font-weight: 500;
      width: 50%;
      text-align: center;
      cursor: pointer;
    }
    .tab.active {
      font-weight: 700;
      border-bottom: 2px solid #913a7e;
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .image {
    border-radius: 50%;
    margin-right: 4px;
  }
  .name {
    margin-left: 8px;
    margin-right: 48px;
    flex-grow: 1;
  }
  .btn {
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    background-color: #913a7e;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1250px) {
  .container.profile.progress {
    flex-direction: column;
    .card.profile {
      width: 100%;
    }
  }
}

@media screen and (max-width: 892px) {
  .navbar {
    display: none;
  }
  .navbar.active {
    position: fixed;
    display: flex;
    .icon {
      display: block;
      fill: #913a7e;
    }
  }

  .main {
    .topbar {
      .search > .icon {
        display: block;
      }
    }
  }

  .message-sidebar {
    display: none;
  }
  .main {
    .messagebar {
      display: none;
    }
  }
  .container {
    .card.messages {
      position: relative;
      .messages {
        width: 100%;
        border: none;
        padding: 0;
        .row > .icon {
          display: block;
        }
      }
      .users {
        display: none;
      }
      .users.active {
        position: absolute;
        z-index: 500;
        right: 0;
        top: 0;
        border-left: 1px solid #dddddd;
        padding: 20px;
        min-width: 200px;
        display: flex;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 0px 4px 4px 0px;
        .close {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 4px;
          .icon {
            display: block;
            cursor: pointer;
            svg {
              width: 16px;
              height: 16px;
              fill: #003595;
            }
          }
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .auth .card {
    padding: 25px 42px !important;
    width: calc(100% - 40px) !important;
    gap: 20px !important;
    .title {
      font-size: 45px !important;
      line-height: 68px !important;
    }
  }

  .gray-container {
    flex-direction: column !important;
  }

  .container {
    .tracking {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .text {
        font-size: 13px;
        line-height: 18px;
        width: 80%;
      }
    }
    .details {
      flex-direction: column;
      .rewards {
        .row {
          flex-wrap: wrap;
          gap: 15px;
        }
      }
    }
    .card.more {
      height: fit-content;
      padding-bottom: 24px;
    }
    .category {
      .card {
        width: 100%;
      }
    }
    .blue {
      .text {
        font-size: 13px;
        line-height: 18px;
        width: 80%;
      }
    }
    .card .rewards {
      flex-wrap: wrap;
      gap: 12px;
    }
    .card.students {
      .col {
        font-size: 13px !important;
        line-height: 18px !important;
        .names {
          gap: 10px !important;
          .image {
            width: 40px !important;
            height: 40px !important;
            min-width: 40px !important;
          }
        }

        .label {
          font-size: 13px !important;
          line-height: 18px !important;
        }
        .name {
          font-size: 13px !important;
          line-height: 18px !important;
        }
        .btn {
          width: 40px !important;
          font-size: 11px !important;
          line-height: 16px !important;
        }
      }
      .progress {
        .progress-line {
          .line {
            width: 120px;
          }
        }
      }
    }
  }
  .container.profile {
    flex-direction: column;
    .card-group {
      .card.progress {
        .goal {
          .detail {
            width: 100%;
            gap: 10px;
            .name {
              width: calc(100% - 60px);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
              text-align: justify;
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.2px;
              // height: 25px;
              overflow: hidden;
            }
          }
        }
      }
    }
    .card.profile {
      width: 100%;
    }
  }

  .card.new {
    .form-group {
      flex-direction: column;
      .options {
        margin: 0;
      }
    }
  }
  .modal {
    .modal-main {
      width: calc(100% - 40px);
      min-width: 0px !important;
      max-width: none !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .auth .card {
    padding: 20px 28px !important;
    .title {
      font-size: 36px !important;
      line-height: 54px !important;
    }
    .btn {
      max-width: 100% !important;
    }
  }
  .card.students {
    .tabs {
      gap: 8px;
      .tab {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .col {
      font-size: 11px !important;
      line-height: 16px !important;
      .name {
        font-size: 11px !important;
        line-height: 16px !important;
        letter-spacing: -0.5px !important;
      }
      .label {
        font-size: 11px !important;
        line-height: 16px !important;
      }
    }
    .progress {
      .progress-line {
        .line {
          width: 80px !important;
        }
      }
    }
  }

  .card.new {
    .form-control {
      .file {
        width: auto;
      }
    }
  }

  .container.profile.progress {
    .card {
      .header {
        flex-direction: column;
        gap: 12px;
      }
      .content {
        .details {
          flex-direction: column;
        }
      }
    }
  }
}

#chat-message-input {
  margin-right: -5px;
}
.chat-messages {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 138px);
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 0.5s;
}
.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}
.chat-message-left {
  margin-right: auto;
}
.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

/* typing bubble */
.chat-bubble {
  background-color: #fff3cd;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1s infinite ease-in-out;
  background-color: #fff0c0;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #ffc003;
  }
  28% {
    transform: translateY(-7px);
    background-color: #e4bf52;
  }
  44% {
    transform: translateY(0px);
    background-color: #dac587;
  }
}
